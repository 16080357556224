
/* Para ocultar los que ya se selccionaron*/

.ant-table-row-selected {
  display: none;
}

/* Inline #23 | http://localhost:3000/audience */

.FiltersComponent .ant-col.ant-col-6 {
  padding: 0 15px;
}

/* Inline #23 | http://localhost:3000/audience */

.tableComportamientos .ant-pagination.ant-table-pagination.ant-table-pagination-right {
  display: none;
}

