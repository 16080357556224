h3.ant-typography, div.ant-typography-h3, div.ant-typography-h3 > textarea, .ant-typography h3 {
    color: #0D33B9 !important;
    line-height: 1;
}
.HeaderDetails .ant-btn.ant-btn-text span {
  position: relative;
  top: -6px;
      color: var(--variant1-blue);

}

/* Inline #23 | http://localhost:3000/audience */

.ant-typography.CifraGeneralResaltadaGA.topCifraBE {
  color: #00C6A2 !important;
}

