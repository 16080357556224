
/* Element | http://localhost:3000/export */

.SeleccionarAudienciasEnExport .ant-modal-body > div:nth-child(1) > div:nth-child(1) {
  /* margin-left: 120px; */
  margin-left: 30px !important;
}
.SeleccionarAudienciasEnExport .ant-col-18 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

/* Element | http://localhost:3000/export */

.SeleccionarAudienciasEnExport .ModalSeleccionAudiencias {
  /* column-width: 3000px; */
  column-width: 3044px;
}

/* Inline #6 | http://localhost:3000/export */

.SeleccionarAudienciasEnExport .ant-table-wrapper.tableComportamientos {
  /* padding: 16px 24px 16px 16px; */
}

.SeleccionarAudienciasEnExport .ant-table-wrapper.tableComportamientos {
  /* padding: 16px 24px 16px 16px; */
  padding: 0;
}

.SeleccionarAudienciasEnExport .ant-table-cell.ant-table-selection-column {
  /* padding: 0 !important; */
}

/* Inline #4 | http://localhost:3000/export */

.SeleccionarAudienciasEnExport .ant-col-18 {
  /* max-width: 75%; */
  max-width: 100%;
}


.SeleccionarAudienciasEnExport .ant-table-filter-column {
  /* display: inline-grid; */
  /* display: inline-block; */
  /* display: flow-root list-item; */
  /* display: contents; */
  display: ruby;
}

.SeleccionarAudienciasEnExport .ant-table-filter-trigger {
  /* margin: -4px -8px -4px 4px; */
  margin: 0; 
}

/* Inline #17 | http://localhost:3000/export */

.SeleccionarAudienciasEnExport .ModalSeleccionAudiencias.tableComportamientos .ant-table-thead {
  /* display: block; */
  display: contents;
}

.ant-table-row-selected {
  display: table-row;
}