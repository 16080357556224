.rotate {
    -moz-transition: all .5s linear;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
}
.rotate.down {
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    transform:rotate(180deg);
}
.FiltersComponent {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.8s;
    -moz-transition: max-height 0.8s;
    transition: max-height 0.8s;
}
.FiltersComponent.open {
    max-height: 500px;
    min-height: 500px;
padding: 20px 50px;
}

button.ant-btn.ant-btn-default.ant-btn-lg.ButtonFiltros {
  /* background: #3A00E5; */
  /* color: #E4CCFF; */
  background: var(--primary-blue);
  color: var(--background1);
  /* text-align: ; dud*/
  text-transform: uppercase;
}
.ant-btn.ant-btn-default.ant-btn-lg.ButtonFiltros.SubtituloRegularGA.OpenFiltros {
  width: auto;
  right: 0;
  border-radius: 0;
}

/* Inline #16 | http://localhost:3000/ */

.ant-btn.ant-btn-default.ant-btn-lg.ButtonFiltros.SubtituloRegularGA span {
  width: 100%;
}
.ChartsGA .ant-avatar.ant-avatar-circle.CifraPrincipalGA {
  color: var(--background1);
}

.FiltrosAudiencica {
  padding: 0 10%;
  display: inline-block;
}

.FiltroAudiencia span {
  background: var(--variant3-blue);
  color: var(--secundary-blue);
  margin: 1px 3px;
  padding: 5px;
  border-radius: 20px;
  line-height: 12px;
display: inline-block;
}

.FiltroAudiencia {
  margin: 1px 3px;
  font-family: "Roboto-Regular";
  font-size: 14px;
  color: var(--black-ga);
  float: left;
}

