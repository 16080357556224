.HeaderGA .ant-divider.ant-divider-vertical.DividerHeaderGA {
  border-left: 2px solid var(--secundary-blue);
  height: 20px;
}
.HeaderGA .TituloBoldGA {
  color: var(--secundary-blue);
}
.HeaderGA .ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.SubtituloRegularGA {
  width: 100%;
}

/* Inline #3 | http://localhost:3000/ */

.HeaderGA .ant-layout-header {
  /* height: 64px; */
  /* line-height: 64px; */
  height: 50.55px;
  line-height: 0;
}
.ant-layout.HeaderGA header {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
}
.HeaderGA .ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
  width: 100%;
}

/* Inline #4 | http://localhost:3000/audience */

/* .ant-menu-horizontal > .ant-menu-item a { */
.HeaderGA .ant-menu-horizontal > .ant-menu-item a {
  color: var(--black-ga);
}

/* Inline #16 | http://localhost:3000/audience */

.HeaderGA .ant-menu-horizontal > .ant-menu-item a:hover {
  color: var(--secundary-blue) !important;
}

/* src/components/AppFrame/AppFrame.css */
.styleContent {
  padding-top: 50.55px;
  height: 100%;
  width: 100%;
  position: absolute;
}

.styleContainer {
  padding: 55px;
  height: 100%;
  /* border-color: red;
  border-style: solid;
  border-width: 5px; */
}

