/*Boton de busqueda*/
.FiltersComponent .ant-space.ant-space-vertical {
  width: 100%;
  padding: 20px 0;
}
.FiltersComponent .Dispositivos .ant-space.ant-space-vertical {
  width: 100%;
  padding: 0px 0 20px 0;
}

.tableComportamientos .ant-table-thead {
  display: none;
}
.tableUbicaciones .ant-table-thead {
  display: none;
}

/* Inline #5 | http://localhost:3000/ */

.ant-tabs.ant-tabs-top {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  background: #FFFFFF;
  border-radius: 0px 0px 10px 10px;
}


.ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-active {
  color: #1e1e1e !important;
}

/* Inline #3 | http://localhost:3000/ */

.FiltersComponent .ant-tabs-tab {
  padding: 0;
  align-items: self-end !important;
  font-family:"Roboto-Regular";
    font-size: 14px;
    color: var(--black-ga);
    text-transform: uppercase;

}
.FiltersComponent .ant-tabs-tab.ant-tabs-tab-active div {
  color: var(--primary-blue) !important;
  font-family: "Roboto-Bold";
  border-bottom: 2px solid var(--primary-blue);
}

.ant-table-wrapper {
  border: 2px solid #AFB1B6;
  border-radius: 8px;
}

.FiltersComponent .ant-tabs-content-holder {
  padding: 0px;
}

/* Inline #7 | http://localhost:3000/ */

.ant-space.ant-space-vertical {
}

.ant-input-group-wrapper.ant-input-search {
  wi: ;
  width: 100% !important;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 0 0;
}

/* Inline #5 | http://localhost:3000/ */


/* Inline #4 | http://localhost:3000/ */


/* Inline #5 | http://localhost:3000/ */

.Columnas1 div {
  font-size: 16px;
  color: #3A00E5;
}

.Columnas1 .ant-col.ant-col-6.ant-col-pull-18 {
  width: 92px !important;
  max-width: 253px;
}

/* Inline #4 | http://localhost:3000/ */

.ant-col-push-6 {
  /* left: 25%; */
  left: 291px !important;
}

/* Inline #5 | http://localhost:3000/ */

.Select1 .ant-select-arrow {
  display: none !important;
}

.Select1 .ant-select-selector {
  border: none !important;
}

.ant-select.Select1.ant-select-multiple.ant-select-show-arrow.ant-select-open.ant-select-show-search {
  top: 0;
}

.ant-col.ColSelect1.ant-col-xs-5.ant-col-xs-offset-1.ant-col-lg-6.ant-col-lg-offset-2 {
  padding-top: 117px !important;
}

/* Inline #5 | http://localhost:3000/ */

.ant-checkbox-inner {
}

.ant-checkbox-inner {
  border-color: #9747FF !important;
  background-color: ;
  width: 20px;
  height: 20px;
}

.FiltersComponent td.ant-table-cell {
      font-family:"Roboto-Regular";
    font-size: 14px;
    color: var(--black-ga);
}

.FiltersComponent .ant-table-tbody > tr > td {
  padding: 4px 0px !important;
}
/* Inline #4 | http://localhost:3000/ */

.ant-checkbox-checked .ant-checkbox-inner {
  /* background-color: #1890ff; */
  background-color: #9747FF !important;
}


.ant-checkbox-inner::after {
  /* top: 50%; */
  /* left: 21.5%; */
  /* width: 5.71428571px; */
  /* height: 9.14285714px; */
  top: 43% !important;
  left: 20.5% !important;
  width: 7.714px !important;
  height: 14.143px !important;
}


/* Inline #4 | http://localhost:3000/ */

.ant-checkbox-checked .ant-checkbox-inner {
  /* background-color: #1890ff; */
  /* border-color: #1890ff; */
  background-color: #9747FF !important;
  border-color: #9747FF !important;
}

/* Inline #4 | http://localhost:3000/ */

.ant-table-tbody > tr > td {
  /* border-bottom: 1px solid #f0f0f0; */
  border-bottom: none !important;
}

/* Inline #5 | http://localhost:3000/ */

.ant-table-cell.ant-table-selection-column {
  padding: 0 !important;
}


/* Inline #5 | http://localhost:3000/ */

.ant-col.ant-col-xs-5.ant-col-xs-offset-1.ant-col-lg-6.ant-col-lg-offset-2 {
}

.Col1 {
  margin-left: 0 !important;
}

/* Inline #4 | http://localhost:3000/ */


.ant-tabs.ant-tabs-top {
  padding: 0px 20px;
}

/* Inline #4 | http://localhost:3000/ */

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #9747FF !important;
  border-color: #9747FF !important;
}
button.ant-btn.ant-btn-default.ant-btn-lg.ButtonFiltros {
    position: absolute;
    width: 274px;
    height: 48px;
    right: 0;
    background: #3A00E5;
    border: 1px solid #3A00E5;
    border-radius: 0px 0px 10px 10px;
    font-size: 16px;
    color: #E4CCFF;
    
}
.FiltersComponent div.ant-tabs.ant-tabs-top div.ant-tabs-nav div.ant-tabs-nav-wrap div.ant-tabs-nav-list div:nth-child(3) {
    border-left: 1px solid var(--primary-blue);
    height: 50px;
    width: 0 !important;
}
.ant-tabs.ant-tabs-top {
    padding: 24px 61px;
    margin-top: -31px;
}

/* Inline #6 | http://localhost:3000/ */

.ant-tag {
  display: flex;
  flex-direction: row-reverse;
  background: #EEF2FB;
  border-radius: 20px;
  border: none;
  color: #0C2375;
  font-weight: 400;
}

.anticon.anticon-close.ant-tag-close-icon {
  margin: auto;
  margin: auto 2px;
  color: #0C2375;
  font-weight: 400;
}

/* Inline #3 | http://localhost:3000/ */

.ant-checkbox-inner {
  /* border-color: #9747FF !important; */
  /* background-color: ; */
  border-color: #0D33B9 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  /* background-color: #9747FF !important; */
  background-color: #0D33B9 !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  /* border: 3px solid #fff; */
}

/* Inline #3 | http://localhost:3000/ */


.Columnas1 div {
  /* color: #3A00E5; */
  color: #181D2E;
}



.ant-checkbox-inner::after {
  /* top: 43% !important; */
  /* left: 20.5% !important; */
  /* width: 7.714px !important; */
  /* height: 14.143px !important; */
  top: 45% !important;
  left: 24.5% !important;
  width: 4.714px !important;
  height: 9.143px !important;
}

.ant-checkbox-inner {
  /* border-color: #0D33B9 !important; */
  border-color: #7084B1 !important;
}

/* Inline #6 | http://localhost:3000/ */

.ant-input-wrapper.ant-input-group {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid #0D33B9;
}

.ant-input-affix-wrapper {
  border: none;
  border-radius: 10px !important;
}

.ant-input-group-addon {
  border-radius: 10px;
  border: none;
}

.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
  border: none;
  border-radius: 10px !important;
}

.ant-table-wrapper.tableComportamientos {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  border: none;
  padding: 16px 24px 16px 16px;
}

/* Inline #2 | http://localhost:3000/ */

.ant-checkbox-inner {
  /* border-radius: 2px; */
  border-radius: 5px;
}

/* Inline #6 | http://localhost:3000/ */

.ant-select-dropdown.ant-select-dropdown-empty.ant-select-dropdown-placement-bottomLeft.ant-slide-up-leave.ant-slide-up-leave-start.ant-slide-up {
display: none;
}
.Demograficos .ant-col.ant-col-8 {
  padding: 20px !important;
}


/* Inline #13 | http://localhost:3000/audience */

.ant-picker-calendar.ant-picker-calendar-mini {
  background: linear-gradient(180deg, #FFFFFF 0%, #FCFDFF 100%);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
}

.ant-picker-cell-inner.ant-picker-calendar-date {
}

.ant-picker-cell-inner.ant-picker-calendar-date.ant-picker-calendar-date-today {
  border-radius: 20px !important;
}

.ant-picker-calendar-date-value {
  border-radius: 20px;
}

.ant-picker-calendar-date-content {
  border-radius: 20px;
}

/* Inline #2 | http://localhost:3000/audience */

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  /* background: #1890ff; */
  background: #0D33B9;
  border-radius: 20px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  /* border: 1px solid #1890ff; */
  /* border-radius: 2px; */
  border: 1px solid #0D33B9 !important;
  border-radius: 20px !important;
}

.ant-picker-cell {
  /* color: rgba(0, 0, 0, 0.25); */
  color: #FCFDFF !important;
}
.ant-picker-cell-in-view {
  /* color: rgba(0, 0, 0, 0.85); */
  color: #0D33B9 !important;
}

.ant-table-wrapper.tableComportamientos {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  border: none;
  padding: 16px 24px 16px 16px;
  background: #ffffff;
}
.FiltersComponent.open {
  background: var(--background1);
}

/* Inline #16 | http://localhost:3000/ */

.FiltersComponent .ant-tabs.ant-tabs-top {
  background: var(--background1);
  max-height: 500px;
min-height: 500px;
}
.FiltersComponent .ant-table-wrapper {
  background: #ffffff;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 16px;
}
.FiltersComponent span.SubtituloRegularGA {
  position: relative;
  top: -5px;
  padding-left: 14px;
}
.FiltersComponent .ResultSearch {
  padding: 50px 0px 0px 50px;
}
.SubtituloRegularGA.definiciondelaaudiencia {
  padding-right: 81px;
}

/* Inline #4 | http://localhost:3000/audience */

.FiltersComponent .ant-select-multiple .ant-select-selector {
  /* padding: 1px 4px; */
  padding: 0;
}

/* Inline #18 | http://localhost:3000/audience */

.FiltersComponent .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
  display: none;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: var(--primary-blue);
}

/* Inline #14 | http://localhost:3000/audience */


/* Inline #4 | http://localhost:3000/audience */

.FiltersComponent .ant-radio-inner::after {
  /* background-color: #1890ff; */
  background-color: var(--primary-blue) !important;
}

.FiltersComponent .ant-radio-checked .ant-radio-inner {
  /* border-color: #1890ff; */
  border-color: var(--primary-blue) !important;
}

.FiltersComponent .ant-radio-inner {
  /* border-width: 1px; */
  border-width: 2px;
}
.FiltersComponent .ant-table-placeholder {
  display: none;
}

/* Element | http://localhost:3000/ */

span.ant-tag:nth-child(1) {
  width: 5;
  float: inline-start;
}

.TagsSelectFilters {
  float: left;
  margin: 5px 3px;
}


.FiltersComponent.open {
  min-height: 500px;
  padding: 20px 50px;
}

.FiltersComponent .ant-tabs-tab.ant-tabs-tab-active div {
  border-bottom: 2px solid var(--primary-blue);
}

.FiltrarAudiencia {
  border-left: 2px solid var(--variant1-blue);
  padding-left: 23px;
}

.FiltersComponent.open .Columnas1 .ant-col {
  padding-bottom: 15px;
}

