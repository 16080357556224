.DispositivosColumns .ant-col.ant-col-8 {
  padding-right: 20px;
}
.FiltersComponent .DispositivosColumns .ant-space.ant-space-vertical {
  padding: 20px 0 0px 0;
}
.tableDispositivos .ant-table-thead {

    display: none;

}

