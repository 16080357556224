@font-face{
    font-family: "Roboto-Regular";
    src: local('Roboto-Regular'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
@font-face{
    font-family: "Roboto-Medium";
    src: local('Roboto-Medium'), url(./fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}
@font-face{
    font-family: "Roboto-Bold";
    src: local('Roboto-Bold'), url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

.SubtituloCharts{
    font-family:"Roboto-Medium";
    font-size: 16px;
    color: var(--primary-blue);
}

.TextCharts{
    font-family:"Roboto-Regular";
    font-size: 14px;
    color: var(--black-ga);
}

.TituloBoldGA {
    font-family:"Roboto-Bold";
    font-size: 18px;
    color: var(--black-ga);
}
.TituloRegularGA {
    font-family:"Roboto-Regular";
    font-size: 18px;
    color: var(--black-ga);
}
.SubtituloBoldGA {
    font-family:"Roboto-Bold";
    font-size: 16px;
    color: var(--black-ga);
}
.SubtituloRegularGA {
    font-family:"Roboto-Regular";
    font-size: 16px;
    color: var(--black-ga);
}
.SubmenuBoldGA {
    font-family:"Roboto-Bold";
    font-size: 14px;
    color: var(--black-ga);
}
.SubmenuRegularGA {
    font-family:"Roboto-Regular";
    font-size: 14px;
    color: var(--black-ga);
}
.ParrafoBoldGA {
    font-family:"Roboto-Bold";
    font-size: 12px;
    color: var(--black-ga);
}
.ParrafoRegularGA {
    font-family:"Roboto-Regular";
    font-size: 12px;
    color: var(--black-ga);
}
@font-face{
    font-family: "Montserrat-Regular";
    src: local('Montserrat-Regular'), url(./fonts/Montserrat/static/Montserrat-Regular.ttf) format('truetype');
}
@font-face{
    font-family: "Montserrat-Medium";
    src: local('Montserrat-Medium'), url(./fonts/Montserrat/static/Montserrat-Medium.ttf) format('truetype');
}
@font-face{
    font-family: "Montserrat-SemiBold";
    src: local('Montserrat-SemiBold'), url(./fonts/Montserrat/static/Montserrat-SemiBold.ttf) format('truetype');
}
.CifraPrincipalGA {
    font-family:"Montserrat-Medium";
    font-size: 30px;
    color: var(--primary-blue);
}
.CifraPrincipalResaltadaGA {
    font-family:"Montserrat-SemiBold";
    font-size: 30px;
    color: var(--primary-blue);
}
.CifraGeneralGA {
    font-family:"Montserrat-Medium";
    font-size: 24px;
    color: var(--primary-blue);
}
.CifraGeneralResaltadaGA {
    font-family:"Montserrat-SemiBold";
    font-size: 24px;
    color: var(--primary-blue);
}

.CifraTablaGA {
    font-family:"Montserrat-SemiBold";
    font-size: 12px;
    color: var(--primary-blue);
}

body {
    --primary-blue: #0D33B9;
    --secundary-blue: #0C2375;
    --variant1-blue: #7084B1;
    --variant2-blue: #E1EBFD;
    --variant3-blue: #EEF2FB;
    --audience-general: #391EDE;
    --audience-new: #FF596F;
    --audience-external: #00C6A2;
    --support-pend: #FFAE11;
    --support-error: #F12D12;
    --support-correct: #009F66;
    --background1: #FAFBFF;
    --black-ga: #181D2E;
}
a:hover {
  color: var(--secundary-blue) !important;
}

.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f9f9f9;
}

.not-found-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 40px;
}

.not-found-title {
    font-size: 2.5em;
    color: #0A3D7E;
    margin: 10px 0;
}

.not-found-text {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
}

.not-found-button-container {
    display: flex;
    gap: 10px;
}

.not-found-button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    background-color: #007BFF;
    color: #FFF;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
.not-found-button-return {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    background-color: #3A00E5;
    color: #FFF;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.not-found-button:hover {
    background-color: #0056b3;
}
