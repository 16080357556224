.DataBaseExternalComponent .ant-input-group-wrapper.ant-input-search.SubtituloRegularGA {
  top: 0;
}
.DataBaseExternalComponent .ant-table-thead {
  background: var(--variant1-blue);
  border-radius: 0;
  font-family: "Roboto-Regular";
  font-size: 14px;
  color: var(--black-ga);
}
.DataBaseExternalComponent .tableDataBaseExternal .ant-btn.ant-btn-default {
  border-radius: 10px;
  background: var(--variant1-blue);
  color: var(--background1);
  font-size: 12px;
  padding: 0;
  height: 22px;
  width: 85px;
  text-align: center;
}
.DataBaseExternalComponent .ant-table-thead tr th {
  background: var(--background1);
  border-radius: 0 !important;
  font-family:"Roboto-Bold";
}

/* Inline #14 | http://localhost:3000/audience */

.DataBaseExternalComponent .ant-table-thead tr th {
  /* border-radius: 8px 8px 0px 0px; */
  border-radius: 0;
}




.DataBaseExternalComponent .ant-table {
  /* line-height: 1.5715; */
  line-height: 0;
}

.DataBaseExternalComponent .ant-table-tbody > tr.ant-table-row-selected > td {
  /* background: #e6f7ff; */
  background: var(--variant2-blue);
}


/* Inline #11 | http://localhost:3000/audience */

.DataBaseExternalComponent .ant-table-thead {
  height: 47px;
}

.DataBaseExternalComponent .ant-table-thead tr th {
  /* background: var(--background1); */
  background: var(--variant3-blue);
}

/* Inline #6 | http://localhost:3000/audience */

.FiltersComponent .DataBaseExternalComponent .ant-table-wrapper {
  /* box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1); */
  /* padding: 16px; */
  box-shadow: none;
  padding: 0;
}


.DataBaseExternalComponent .ant-table-row.ant-table-row-level-0.ant-table-row-selected {
  background: var(--variant2-blue) !important;
}

/* Inline #14 | http://localhost:3000/audience */

.DataBaseExternalComponent .ant-tag.ant-tag-has-color.tagExports {
  /* padding: 2px 12px; */
  padding: 2px 0px;
}
.DataBaseExternalComponent .ant-table.ant-table-small .ant-table-title,
.DataBaseExternalComponent .ant-table.ant-table-small .ant-table-footer,
.DataBaseExternalComponent .ant-table.ant-table-small .ant-table-thead > tr > th,
.DataBaseExternalComponent .ant-table.ant-table-small .ant-table-tbody > tr > td,
.DataBaseExternalComponent .ant-table.ant-table-small tfoot > tr > th,
.DataBaseExternalComponent .ant-table.ant-table-small tfoot > tr > td {
  padding: 0;
}
.ColHeader2 {
line-height: 20px
}
.DataBaseExternalComponent .ant-table-row-selected {
  display: contents;
}
.tableDataBaseExternal .ant-table-row-selected {

    display: contents;

}
