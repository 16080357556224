.ListAudiencesComponent .ant-table-pagination-right {
  justify-content: flex-start;
}

/* Inline #6 | http://localhost:3000/ */

.ListAudiencesComponent .ant-col.ant-col-6.ColCard {
  padding: 10px;
}
.ant-table-column-sorter {
  display: none;
}
.ListExportTable .ant-table-row-selected {
  display: contents;
}
.tableListExport .ant-table-row-selected {
    display: contents;
}
.ListAudiencesComponent .ant-row.ant-row-middle.MenuListAudience {
  padding: 20px 0px;
}
.BotonCargar .ant-btn.ant-btn-default {
  border: none;
  font-family: "Roboto-Regular";
  font-size: 16px;
  color: var(--black-ga);
}
.ListAudiencesComponent .ant-switch-checked {
  background-color: var(--primary-blue);
}
.ant-row.RowVisualizacion Button {
  border: none;
}
.ListAudiencesComponent {
  padding: 20px 50px;
}

/* Inline #19 | http://localhost:3000/audiences */

.ListAudiencesComponent .ant-btn.ant-btn-primary.SubtituloRegularGA.Button1 {
  background: #0D33B9;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: var(--background1);
  margin-left: 21px;
}

/* Inline #19 | http://localhost:3000/audiences */

.ant-modal-content {
  background: #FFFFFF;
  box-shadow: 2px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 8px 0 50px 0;
}

.ant-modal {
}

.active-button {
  background-color: lightgray;
  border-color: darkgray;
  color: black;
}

.ant-modal-header {
  background: #FFFFFF;
  border-radius: 15px;
  text-align: center;
  font-size: 18px;
}

.ant-modal-header div {
  font-size: 18px;
  font-family: "Roboto-Bold";
  text-transform: uppercase;
}

.ant-modal-body p {
  text-align: center;
  font-size: 16px;
}

.ant-modal-footer {
  text-align: center;
}

.ant-modal-footer button {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 89px;
  height: 42px;
  font-size: 16px;
  margin: 0px 30px;
}

.ant-modal-footer .ant-btn.ant-btn-default {
  background: var(--variant3-blue);
  color: var(--black-ga);
}

.ant-modal-footer .ant-btn.ant-btn-primary {
  background: var(--primary-blue);
  color: var(--background1);
}

/* Inline #21 | http://localhost:3000/audiences */

.ListAudiencesComponent tr:nth-child(2n) td {
  background: rgb(248, 250, 255);
}


/* Cambios en tarjetas */

.ListAudiencesComponent .ant-card.ant-card-bordered {
  background: #FFFFFF;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none;
}

.ListAudiencesComponent .ant-card-head-title {
  color: #181D2E;
  font-family: "Roboto-Regular";
  font-size: 14px;
}

.ant-card-head {
  margin: 0 20px;
  padding: 0;
  border-bottom: 1px solid #B6BFD6;
}

.ant-typography.ant-typography-secondary {
  font-size: 14px;
  color: #B1BBD2;
  float: right;
}

.ant-switch-handle::before {
  background: var(--primary-blue);
}

.ant-col.ant-col-7 {
  /* display: table-row-group; */
  /* display: table; */
  /* display: inline-table; */
  /* display: inline list-item; */
  /* display: undefined; */
}

.ant-switch {
  float: right;
}

/* Inline #17 | http://localhost:3000/audiences */

.ListAudiencesComponent .ant-switch-checked {
  /* background-color: var(--primary-blue); */
  background-color: var(--variant2-blue);
  float: right;
}

/* Inline #21 | http://localhost:3000/audiences */

.optionsButton {
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 0 0 0 25px;
}

/* Inline #22 | http://localhost:3000/audiences */

.ComportamientosCard {
  height: 65px;
  overflow-y: scroll;
  margin-bottom: 0px;
}

.ColComportamientosCard .ant-typography.ant-typography-secondary {
  float: left;
}

/* Inline #22 | http://localhost:3000/audiences */

.ant-col.ColComportamientosCard {
  width: 100%;
}

