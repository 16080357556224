.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  display: none;
}
.HeaderGA .ant-menu-item-active.ant-menu-item-selected a {
  color: var(--black-ga);
  font-family: "Roboto-Bold";
  font-size: 16px;
}
.HeaderGA .ant-menu-horizontal > .ant-menu-item-selected a {
    color: var(--black-ga);
    font-family: "Roboto-Bold";
    font-size: 16px;
}
.HeaderGA .ant-menu-horizontal {
  border: 0;
}
