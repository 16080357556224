
/* Inline #4 | http://localhost:3000/exports */

.ant-table-wrapper {
  /* width: 317px; */
  width: auto;
}

/* Inline #13 | http://localhost:3000/exports */

.ant-table-thead {
  background: #FAFBFF;
  border-radius: 8px 8px 0px 0px;
}

.ant-table-thead th {
  background: #FAFBFF;
  border-radius: 8px 8px 0px 0px;
}


.ant-tag.ant-tag-geekblue {
  background: #F12D12;
  color: #FAFBFF;
}

/* Inline #4 | http://localhost:3000/exports */

.ant-table-wrapper {
  /* border: 2px solid #AFB1B6; */
  border: none;
}

/* Inline #2 | http://localhost:3000/exports */

.ant-table-thead th.ant-table-column-sort {
  background: #FAFBFF;
  border-radius: 8px 8px 0px 0px;
}

/* Inline #13 | http://localhost:3000/exports */

.ant-table-thead tr th{
  background: var(--background1);
  border-radius: 8px 8px 0px 0px;
  font-family:"Roboto-Bold";
}

.ant-table-thead th {
  background: #FAFBFF;
  border-radius: 8px 8px 0px 0px;
}


.ant-tag.ant-tag-geekblue {
  background: #F12D12;
  color: #FAFBFF;
}

.MenuExports .ant-btn.ant-btn-primary.Button1 {
  background: var(--primary-blue);
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 400;
  width: 100%;
  color: var(--background1);
}

/* Inline #4 | http://localhost:3000/exports */

.ant-table-wrapper {
  /* border: 2px solid #AFB1B6; */
  border: none;
}

/* Inline #2 | http://localhost:3000/exports */

.ant-table-thead th.ant-table-column-sort {
  background: #FAFBFF;
  border-radius: 8px 8px 0px 0px;
}
.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  display: block;
}
.ExportsTable .ant-table-column-sorter.ant-table-column-sorter-full {
  display: none !important;
}
.site-layout-background {
  padding: 0px 49px !important;
}
.MenuExports .ant-space.ant-space-vertical {
  width: 100%;
  padding: 0 10px;
}
.ant-row.ant-row-middle.MenuExports {
  padding: 15px 0;
}
.ant-table-wrapper.ExportsTable.SubmenuRegularGA thead span{
  color: var(--black-ga);
  font-family:"Roboto-Bold";
}
.ant-table-wrapper.ExportsTable.SubmenuRegularGA span{
  color: var(--black-ga);
}
.ant-tag.ant-tag-has-color.tagExports {
  color: var(--background1) !important;
  font-size: 14px;
  padding: 2px 12px;
  display: table;
  margin: auto;
  width: 80px;
  height: 24px;
  text-align: center;
}

.ant-form.ant-form-horizontal.FormExports {
  text-align: center;
  padding: 10% 35%;
  align-content: initial;
  display: block;
}

.HeaderGA h2.TituloBoldGA {
  text-transform: uppercase;
  color: var(--black-ga);
  text-align: center;
}
p.SubtituloRegularGA {
  text-align: center;
}
/* Inline #16 | http://localhost:3000/exports */

.FormExports input {
  border: 1px solid #181D2E;
  border-radius: 10px;
  height: 40px;
}

.FormExports textarea.ant-input {
  border: 1px solid #181D2E;
  border-radius: 10px;
  height: 40px;
}

.FormExports button.ant-btn.ant-btn-primary {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: var(--primary-blue);
  color: var(--background1);
  font-size: 16px;
  height: 40px;
  width: 174px;
}

#basic_descriptionExport {
  /* align-content: flex-end; */
  /* align-content: normal; */
  /* align-content: center; */
  /* align-content: undefined; */
  height: 147px;
  align-content: end;
}
.CalendarRowExports .ant-alert.ant-alert-info.ant-alert-no-icon {
  border: 1px solid #181D2E;
  border-radius: 10px;
  background: none;
  margin-bottom: 15px;
}
.SelectDateExports .ant-btn.ant-btn-primary.SubtituloRegularGA.Button1 {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: var(--primary-blue);
  color: var(--background1);
  font-size: 16px;
  text-transform: uppercase;
  width: 174px;
  height: 40px;
  margin-top: 20px;
  float: inline-end;
}
.SelectDateExports {
  padding: 20px 90px;
}
.CalendarRowExports .Col1 {
    padding-right: 10px;
}
.CalendarRowExports .Col2 {
    padding-left: 10px;
}
.ExportsComponent .ant-switch-checked {
  background-color: var(--variant2-blue);
}
.ExportsComponent .ant-card.ant-card-bordered {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.ExportsComponent .ColCard {
  padding: 9px;
}
.BuscarAudiencias .ant-btn.ant-btn-primary.SubtituloRegularGA.Button1 {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: var(--primary-blue);
  color: var(--background1);
  width: 267px;
  height: 42px;
      float: inline-end;

}
.BuscarAudiencias .ant-btn.ant-btn-primary.SubtituloRegularGA.Button1.ButtonBuscar {
  height: auto;
}
.BuscarAudiencias {
  padding: 20px 100px;
}

/* Inline #4 | http://localhost:3000/exports */

.BuscarAudiencias .ant-table-tbody > tr.ant-table-row-selected > td {
  /* background: #e6f7ff; */
  background: none;
}

.BuscarAudiencias .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  /* background-color: #1890ff; */
  background-color: var(--primary-blue);
}

/* Inline #14 | http://localhost:3000/exports */

.BuscarAudiencias .ant-table-thead tr th {
  /* background: var(--background1); */
  background: var(--variant3-blue);
  border-radius: 0;
}

/* Inline #14 | http://localhost:3000/exports */

.BuscarAudiencias .ant-space.ant-space-vertical {
  /* padding: 0 10px; */
  padding: 0;
}

.optionsButton {
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 0 0 0 25px;
}
