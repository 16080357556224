
/* Inline #20 | http://localhost:3000/login */

.UsersManagement {
  text-align: center;
  padding-top: 90px;
  min-height: 90vh;
}

.SignIn input {
  width: 100%;
  margin-top: -4px;
  height: 40px;
  border: 1px solid #181D2E;
  border-radius: 10px;
  margin-bottom: 5px;
  font-size: 14px;
  padding: 8px 16px;
}

.SignIn {
  margin: 0 38%;
}

.UsersManagement button {
  background: #0D33B9;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 174px;
  height: 40px;
  padding: 0px 15px;
  font-size: 16px;
  color: #FAFBFF;
  font-weight: 600;
  border: none;
}

.SignIn p {
  color: #7084B1;
  text-align: left;
  margin: 0;
}

.SignIn .password {
  width: 100%;
  margin-top: -4px;
  height: 40px;
  border: 1px solid #181D2E;
  border-radius: 10px;
  margin-bottom: 5px;
  font-size: 14px;
  padding: 8px 16px;
}

/* Inline #21 | http://localhost:3000/login */

.h1Login {
  font-weight: 700;
  font-size: 25px;
  text-transform: uppercase;
  color: #0C2375;
}

.h3Login {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-align: left;
  margin: 25px;
}

.forgotPassword span {
  font-weight: 400;
  font-size: 14px;
  color: #0D33B9;
  text-decoration-line: underline;
}

.SignIn p.forgotPassword {
  text-align: end;
}

.UsersManagement button.buttonSignUp {
  color: #0D33B9;
  background: #EEF2FB;
  margin-top: 30px;
  font-weight: 400;
}

/* Inline #18 | http://localhost:3000/login */

.UsersManagement button {
  text-transform: uppercase;
  margin-top: 30px;
}


.SignIn.SignUp .ant-col.ant-col-12.Col1 {
  padding-right: 10px;
}

.SignIn.SignUp .ant-col.ant-col-12.Col2 {
  padding-left: 10px;
}

