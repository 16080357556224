
/* Inline #6 | http://localhost:3000/export/fed780aa-dc8f-43b9-85f7-a58ffd1205f2 */

.ModalSeleccionAudiencias.tableComportamientos .ant-table-thead {
  display: block; 
}

/* Inline #6 | http://localhost:3000/export/fed780aa-dc8f-43b9-85f7-a58ffd1205f2 */

.ModalSeleccionAudiencias.tableComportamientos .ant-table-cell.ant-table-selection-column {
  /* padding: 0 !important; */
  padding: 6px;
}


